import styled from "styled-components"

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.tiempos};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.darkGreen};
  @media (min-width: 768px) {
    font-size: 33px;
  }
`

export const TitleHeading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.tiempos};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.darkGreen};
  @media (min-width: 768px) {
    font-size: 33px;
  }
`

export const SansTitle = styled(Title)`
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  font-size: 28px;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 33px;
  }
`

export const MediumTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`
