import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Title, MediumTitle } from "../../components/typography/title"
import back from "../../static/images/back-arrow.svg"
import { getLocalizedString } from "../../utils/localization"
import { Logo } from "@rily/components"

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${props => props.theme.colors.floralWhite};
`

const Container = styled.div`
  background-color: ${props => props.theme.colors.floralWhite};
  height: calc(80vh - 92px);
  padding: 46px 55px;
  display: grid;
`

const Intro = styled(Title)`
  text-align: center;
  margin: 0 auto;
`

const BlackLink = styled(Link)`
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

const BackIcon = styled.img`
  cursor: pointer;
  margin-right: 10px;
  width: 15px;
`

const About = () => {
  return (
    <AboutContainer>
      <Container>
        <Content>
          <Intro>{getLocalizedString("not_found.intro")}</Intro>

          <BlackLink to="/">
            <BackIcon src={back} />

            <MediumTitle>{getLocalizedString("not_found.goto")}</MediumTitle>
          </BlackLink>
        </Content>
      </Container>
    </AboutContainer>
  )
}

export default About
